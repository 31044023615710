/* eslint-disable no-bitwise */
<template>
  <div class="infomation-and-adress">
    <!--===============  Thông tin =================-->
    <h3 class="d-flex align-items-center mb-2">
      <feather-icon
        size="20"
        icon="InfoIcon"
        class="mr-75"
      />
      <span>
        Thông tin đơn vị
      </span>
    </h3>
    <div class="infomation-and-adress__input__01">
      <b-row>
        <!--===============  Tên đơn vị =======================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="name"
          >
            <label for="name">Tên đơn vị<span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="name"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-form-group>
        </b-col>
        <!--===============  Số điện thoại =================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="phoneNumber"
          >
            <label for="phoneNumber">Số điện thoại</label>
            <b-form-input
              v-model="dataInput.phoneNumber"
            />
          </b-form-group>
        </b-col>
        <!--=============== Email=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="email"
          >
            <label for="email">Email<span class="text-danger">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required"
              :custom-messages="email"
            >
              <b-form-input
                v-model="dataInput.email"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-form-group>
        </b-col>
        <!--=============== Địa chỉ=================-->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group
            label-for="address"
          >
            <label for="address">Địa chỉ</label>
            <b-form-input
              v-model="dataInput.address"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <!-- <div class="switch mt-1">
            <label
              for=""
              class="m-0"
            >Sử dụng trang chủ</label>
            <b-form-checkbox
              switch
            ></b-form-checkbox>

          </div> -->
        </b-col>
      </b-row>
    </div>
    <!-- <custtom-title
      :icon="'ImageIcon'"
      :title="'Nhận diện'"
    /> -->
    <h3 class="d-flex align-items-center mb-2">
      <feather-icon
        size="20"
        icon="ImageIcon"
        class="mr-75"
      />
      <span>
        Nhận diện
      </span>
    </h3>
    <div class="infomation-and-adress__input__01">
      <b-row>
        <!--=============== Biểu trưng=================-->
        <b-col>
          <CusttomAvatar
            id="BT"
            idFake="idBT"
            :urlImg="dataInput"
            idInput="BTInput"
            label="Biểu trưng"
            width="300px"
            height="150px"
            class="image-logo"
            @url="imgBT($event)"
          />
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <CusttomAvatar
            id="BTT"
            idFake="idBTT"
            :urlImg="dataInput"
            idInput="BTTInput"
            label="Biểu trưng trang"
            class="image-logo"
            @url="imgBTT($event)"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BFormCheckbox,
  BFormTextarea,
  BDropdown,
  BMedia,
  BFormInput,
  BAvatar,
  BDropdownItem,
  BFormGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import CusttomAvatar from '@/views/settings/setting/pages/components/CusttomAvatar.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
    CusttomAvatar,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,

  },
  props: {
    dataInput: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      name: {
        required: 'Tên đơn vị là bắt buộc',
      },
      email: {
        required: 'Email là bắt buộc',
      },
    }
  },

  methods: {
    imgBT(val) {
      this.dataInput.logo = val
    },
    imgBTT(val) {
      this.dataInput.favicon = val
    },
  },
}
</script>

<style lang="scss">
.switch{
display: flex;
align-items: center;
justify-content: space-between;
}

#idBT{
  .remove-file {
    position: absolute;
    margin-top: 35px;
    margin-left: -195px;
    width: 40px;
    height: 40px;
  }
}
.image-logo {
  .image-preview {
    img {
      background: #EFEFEF !important;
      object-fit: contain !important;
    }
  }
}
</style>
