<template>
  <div
    id="addBusiness"
  >
    <my-tabs
      :listTabs="listTabs"
      defaultActive="user-edit-tab-account"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div>
    </div>
    <div class="page-container addBusiness-fix ">
      <validation-observer ref="rule">
        <information
          id="info"
          ref="GeneralInformation"
          :dataInput="dataInput"
          class="tab-pane"
          :class="{'active':activeItem==='info'}"
        />
        <Domain
          id="domain"
          ref="WorkerInformation"
          :dataInput="dataInput"
          class="tab-pane"
          :class="{'active':activeItem==='domain'}"
        />
        <home-pages
          id="homepages"
          :dataInput="dataInput"
          class="tab-pane"
          :class="{'active':activeItem==='homepages'}"
        />
        <setting-other
          id="other"
          :dataInput="dataInput"
          class="tab-pane"
          :class="{'active':activeItem==='other'}"
          @showModalDownloadDateLog="showModalDateLog"
        />
      </validation-observer>
      <div class="add-business__button">
        <div class="add-business__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="save()"
          >
            Lưu lại
          </b-button>

        </div>
      </div>
    </div>
    <modal-date-log
      id="modalDateLog"
      title="Tải file log"
      @handlerDownloadFileLog="handlerDownloadFileLog"
    />
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import MyTabs from '@/components/tabs/MyTabs.vue'
import Information from './tag-list/Infomation.vue'
import Domain from './tag-list/Domain.vue'
import HomePages from './tag-list/HomePages.vue'
import ModalDateLog from './components/ModalDateLog.vue'
import SettingOther from './tag-list/SettingOther.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BButton,
    MyTabs,
    ValidationObserver,
    Information,
    Domain,
    HomePages,
    SettingOther,
    ModalDateLog,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      checkCodeData: '',
      checkEmailData: '',
      activeItem: 'info',
      listTabs: [
        {
          key: 'info',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'domain',
          title: 'Tên miền',
          icon: 'GlobeIcon',
          isDisabled: false,
        },
        {
          key: 'homepages',
          title: 'Trang chủ',
          icon: 'LayoutIcon',
          isDisabled: false,
        },
        {
          key: 'other',
          title: 'Cài đặt khác',
          icon: 'ListIcon',
          isDisabled: false,
        },

      ],
      dataSystem: {},
      dataInput: {
        name: '',
        address: '',
        phoneNumber: '',
        logo: '',
        favicon: '',
        banner: '',
        title: '',
        titleContent: '',
        url: '',
        managementId: '',
        email: '',
        passwordExpired: '',
        logLevel: null,
        maxFileLogSize: 1,
        maxSizeRollBackup: 2,
        appendToFile: true,
      },
    }
  },
  watch: {
    dataSystem(val) {
      if (val) {
        this.dataInput = {
          name: this.dataSystem.name,
          address: this.dataSystem.address,
          phoneNumber: this.dataSystem.phoneNumber,
          logo: this.dataSystem.logo,
          favicon: this.dataSystem.favicon,
          banner: this.dataSystem.banner,
          title: this.dataSystem.title,
          titleContent: this.dataSystem.titleContent,
          url: this.dataSystem.url,
          managementId: this.dataSystem.managementId,
          email: this.dataSystem.email,
          passwordExpired: this.dataSystem.passwordExpired,
          logLevel: this.dataSystem.logLevel,
          maxFileLogSize: this.dataSystem.maxFileLogSize,
          maxSizeRollBackup: this.dataSystem.maxSizeRollBackup,
          appendToFile: this.dataSystem.appendToFile,
        }
      }
    },

  },

  created() {
    this.fecthInfoSystem()
  },
  methods: {
    setActive(tabActive) {
      this.activeItem = tabActive
    },
    async save() {
      const success = await this.$refs.rule.validate()
      let message
      let status
      this.$showAllPageLoading()
      if (success) {
        const payload = {
          ...this.dataInput,
          passwordExpired: parseInt(this.dataInput.passwordExpired, 10),
          maxFileLogSize: parseInt(this.dataInput.maxFileLogSize, 10),
          maxSizeRollBackup: parseInt(this.dataInput.maxSizeRollBackup, 10),
        }
        await axiosApiInstance.put(ConstantsApi.UPDATE_SYSTEM, payload).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            message = 'Chỉnh sửa thành công'
            status = 'success'
            this.fecthInfoSystem()
          }
        }).catch(e => {
          this.$hideAllPageLoading()
          status = 'danger'
          message = e.response.data?.errors[0]
        })
      } else {
        message = 'Vui lòng chuyển qua thẻ Cài đặt khác để nhập trường dung lượng và trường số file backup'
        status = 'danger'
        this.$hideAllPageLoading()
      }
      this.$root.$bvToast.toast(message, {
        title: 'Thông báo',
        variant: status,
        toaster: this.$toastPosition,
        solid: true,
      })
    },

    showModalDateLog() {
      this.$bvModal.show('modalDateLog')
    },

    async handlerDownloadFileLog(dateLog) {
      await axiosApiInstance({
        url: '/Logger/log-by-param', // your url
        method: 'GET',
        responseType: 'blob',
        params: { dateLog },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file_log') // or any other extension
        document.body.appendChild(link)
        link.click()
      }).catch(e => {
        this.$bvToast.toast(e.response.statusText ? 'Không có file log' : e.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async fecthInfoSystem() {
      const { data } = await axiosApiInstance.get(ConstantsApi.GET_INFO_SYSTEM)
      this.dataSystem = data
    },
  },
}
</script>

<style lang="scss">
#addBusiness{
   .add-business__button{
     padding-top:40px;
     display: flex;
     &__item{
       margin-right: 20px;
     }
   }
}

</style>
